import React, { useState, useRef, useEffect } from 'react'
import './App.css'
import Chat from './components/Chat'
import MixpanelUtil from './mixpanel'
import Api from './api'
import Spinner from './components/Spinner'

const mp = MixpanelUtil.getInstance()

const DEMO_USER = {
	created_at: '2024-07-08 20:44:53.135000',
	email: 'demo@rowsie.ai',
	id: '377a1153-02c2-4bf0-adc2-7c84e37180b3',
	username: 'demo@rowsie.ai',
}

enum SpreadsheetOptions {
	StartupCapTable = 'StartupCapTable',
	GlobalPopulation = 'GlobalPopulation',
	SalespersonCompensation = 'SalespersonCompensation',
}

const spreadsheetDetails = {
	[SpreadsheetOptions.StartupCapTable]: {
		spreadsheetId: '136447bd-2d07-4ddb-9090-59e98e432468',
		link: 'https://docs.google.com/spreadsheets/d/1n-NcSF6qLBjrIbRMEXHucJN9ce-DsJOt/edit?rm=demo',
		title: 'Startup Cap Table Analysis',
	},
	[SpreadsheetOptions.GlobalPopulation]: {
		spreadsheetId: 'd873f2a3-2186-40c9-9651-7a6bdeb9f024',
		link: 'https://docs.google.com/spreadsheets/d/1zYmTn70mFOo9D5pDjbf1qjmv-bmlmDx8/edit?rm=demo',
		title: 'Global Population Data',
	},
	[SpreadsheetOptions.SalespersonCompensation]: {
		spreadsheetId: '1efbe494-dc92-40f7-9301-00d934fc6235',
		link: 'https://docs.google.com/spreadsheets/d/1fgALR3nZLld0fYYRHf78-djdz1Fk1G-I/edit?rm=demo',
		title: 'Salesperson Compensation Analysis',
	},
}

const App = () => {
	console.log(process.env)
	const [spreadsheetId, setSpreadsheetId] = useState(
		spreadsheetDetails[SpreadsheetOptions.StartupCapTable].spreadsheetId,
	)
	const [embedLink, setEmbedLink] = useState(
		spreadsheetDetails[SpreadsheetOptions.StartupCapTable].link,
	)

	const api = Api.getInstance()

	useEffect(() => {
		const bearer = process.env.REACT_APP_DEMO_BEARER_TOKEN
		api.token = bearer
	}, [api])

	const iframeRef = useRef(null)

	const handleSpreadsheetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		mp.track('select_spreadsheet', {
			spreadsheet_id: e.target.value,
		})
		const selectedOption = e.target.value as SpreadsheetOptions
		const details = spreadsheetDetails[selectedOption]
		setSpreadsheetId(details.spreadsheetId)
		setEmbedLink(details.link)
	}

	return (
		<div className='app-container'>
			{!spreadsheetId ? (
				<Spinner />
			) : (
				<div className='content'>
					<div className='excel-viewer'>
						<iframe
							title='Excel'
							ref={iframeRef}
							height='100%'
							width='100%'
							src={embedLink}
						/>
					</div>
					<div className='sidebar'>
						<Chat displayName={''} spreadsheetId={spreadsheetId} />
					</div>
				</div>
			)}
			<footer
				className='app-footer'
				style={{
					backgroundColor: '#125d56',
					color: 'white',
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<p style={{ marginRight: '10px' }}>Want to try Rosie on different models?</p>
				<select
					style={{
						padding: '10px 20px',
						backgroundColor: '#61dafb',
						color: '#125d56',
						border: 'none',
						borderRadius: '5px',
						cursor: 'pointer',
						textDecoration: 'none',
					}}
					onChange={handleSpreadsheetChange}
				>
					{Object.values(SpreadsheetOptions).map((option) => (
						<option key={option} value={option}>
							{spreadsheetDetails[option].title}
						</option>
					))}
				</select>
			</footer>
		</div>
	)
}

export default App
